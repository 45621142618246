body {
  background-color: #eee;
}

.head {
  width: 100%;
  height: 100px;
  background-color: #191874;
  display: flex; // Cambiado a flex
  justify-content: flex-start; // Para alinear a la izquierda
  align-items: center;
  padding-inline: 50px; 

}

.logo {
  max-width: 40%;
  width: auto; // Cambiar para evitar tamaño excesivo
  height: auto;
  margin-left: 10px;
  margin-top: 5px;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .App {
    max-width: 700px;
    width: 80%; // Mantener ancho responsivo
    padding: 10px; // Ajustar padding para pantallas pequeñas
    text-align: center;
    background-color: white;
    border: 1px solid #aaa;
    box-shadow: 0px 0px 10px #ccc;
    position: relative;
    align-items: center;
    margin-top: 40px;

  }
}