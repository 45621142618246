.PasswordChange {
  padding: 5%;
  max-width: 500px;
  margin: auto;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #191874;
  }

  p {
    text-align: center;
    margin-bottom: 20px;
    color: #464646;
  }

  label {
    display: block;
    margin: 20px 0 10px;
    font-size: 1.2rem;
    color: #191874;
  }

  .password-input-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    input[type="password"],
    input[type="text"] {
      width: 100%;
      padding: 12px 40px 12px 16px;
      border-radius: 20px;
      border: 1px solid #ccc;
      font-size: 1rem;
      box-sizing: border-box;
      margin-top: 10px;
    }

    .toggle-password {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      user-select: none;

      svg {
        stroke: #191874;
        width: 24px;
        height: 24px;
      }
    }
  }

  .error {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-top: 5px;
    text-align: center;
  }

  button {
    width: 80%;
    max-width: 400px;
    margin-top: 30px;
    padding: 15px;
    border: none;
    border-radius: 20px;
    font-size: 1.5rem;
    background-color: #191874;
    color: #31e809;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    transition: background-color 0.3s ease;

    &:disabled {
      background-color: #ccc;
      color: #464646;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #2a287d;
    }
  }

  @media (max-width: 768px) {
    padding: 10% 5%;
    
    h1 {
      font-size: 1.5rem;
    }

    label {
      font-size: 1rem;
    }

    button {
      font-size: 1.2rem;
      padding: 12px;
    }
  }
}
