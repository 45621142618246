img {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    width: 60%;
    height: auto;
  }
  
  p {
    text-align: center;
  }