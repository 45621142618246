body {
  margin: 0;
}

p,
h1,
footer {
  margin-left: 2%;
}

footer {
  color: #696660;
}

div {
  font-family: sans-serif;
}

@media (max-width: 768px) {
  
  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

}